

footer {
    background-color: #343a40;
    color: white;
    padding: 10px 0;
    /*position: relative;*/
    /*bottom: 0;*/
    /*width: 100%;*/
    min-height: 50px;
}
