

.article {
    /*background-color: #ECEFF1;*/
    background-color: var(--main-color-50);
    border: 1px solid #d4d4d8;
    border-radius: 1rem;
    min-height: 6rem;

    h5 {
        color: var(--main-color-950);
    }
}

.article:hover {
    background-color: var(--main-color-100);

    h5 {
        color: var(--main-color-900);
    }
}

.published {
    color: var(--main-color-700);
    margin: 0;
    margin-top: 1rem;
}

.tag {
    position: absolute;
    top: 0.4rem;
    right: 1rem;
    color: var(--main-color-900);
    font-weight: bold;
}

/*.filter-button {*/
/*    background-color: var(--main-color-500);*/
/*    color: var(--main-text-color);*/
/*    margin: 5px;*/
/*    padding: 10px;*/
/*    text-align: center;*/
/*    width: 100%;*/
/*}*/

/*.filter-button:hover {*/
/*    background-color: var(--main-color-600);*/
/*}*/

/*.selected {*/
/*    background-color: var(--main-color-700);*/
/*}*/

.filter-button {
    color: var(--main-color-800);
    text-decoration: none;
    text-shadow: #7bd5fe;
    /*color: var(--main-text-color);*/
}

.filter-button:hover {
    color: var(--main-color-600);
}

.selected {
    text-decoration: underline;
    color: var(--main-color-900);
}

a {
    color: #FFF;
    text-decoration: none;
}
