.font-size-2 {
    font-size: 18px;
}

.border-box {
    position: relative;
    display: inline-block; /* This is to keep the width based on the content */
    padding: 10px; /* Add padding if necessary */
}

.border-box::before,
.border-box::after {
    content: '';
    position: absolute;
    /*background-color: #0077b6; !* Set the border color *!*/
    background-color: #33b6ff; /* Set the border color */
    box-shadow: -2px -2px 4px 1px #43bdff;
}

.border-box::before {
    top: 0;
    left: 0;
    width: 20%;
    height: 0px; /* Adjust the thickness of the border */
}

.border-box::after {
    top: 0;
    left: 0;
    width: 0px; /* Adjust the thickness of the border */
    height: 50%;
}
