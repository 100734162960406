.border-box-1 {
    position: relative;
    display: inline-block;
    padding: 10px;
}

.border-box-1::before,
.border-box-1::after {
    content: '';
    position: absolute;
    background-color: #33b6ff;
    box-shadow: 35px -10px 4px 1px #43bdff;
}

.border-box-1::before {
    top: 0;
    left: 0;
    width: 40%;
    height: 0px;
}

.border-box-1::after {
    top: 0;
    left: 0;
    width: 0px;
    height: 50%;
}

.border-box-2 {
    position: relative;
    display: inline-block;
    padding: 10px;
}

.border-box-2::before,
.border-box-2::after {
    content: '';
    position: absolute;
    background-color: #33b6ff;
    box-shadow: -45px 20px 4px 1px #43bdff;
}

.border-box-2::before {
    bottom: 0;
    right: 0;
    width: 40%;
    height: 0px;
}

.border-box-2::after {
    bottom: 0;
    right: 0;
    width: 0px;
    height: 50%;
}

.justify-text {
    text-align: justify;
}
