.home-content {
    width: 100%;
    height: 100vh;
}

.video-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -100;
}

video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    filter: brightness(50%);
    top: 0;
    left: 0;
}

.home-text {
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
