@font-face {
  font-family: "ptregular2";
  src: local('ptregular2'), url(./assets/PTSans-Regular2.ttf) format('truetype');
}

:root {
  --main-bg-color: #0077b6;
  --main-text-color: #f0f9ff;
  --main-color-50: #f0f9ff;
  --main-color-100: #dff2ff;
  --main-color-200: #b9e7fe;
  --main-color-300: #7bd5fe;
  --main-color-400: #34c0fc;
  --main-color-500: #0aa9ed;
  --main-color-600: #0087cb;
  --main-color-700: #0077b6;
  --main-color-800: #055b87;
  --main-color-900: #0a4b70;
  --main-color-950: #07304a;
}

body {
  font-family: "ptregular2", sans-serif !important;
  background-color: #f8f9fa;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

main {
    /*flex: 1;*/
    min-height: 77.9vh;
}

a {
  cursor: pointer;
}

.blob {
  position: absolute;
  display: block;
  z-index: -100;
  right: 0;
  width: 50%;
  height: 50%;
  top: 0;
  /*margin: 100px 350px;*/
  margin: 10vh 35vh;
  opacity: 0.3;
}
