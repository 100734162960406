.article-title {
    color: var(--main-color-950) !important;
    font-weight: bold !important;
}

.article-desc {
    color: var(--main-color-900);
    margin-top: 1rem;
}

.published {
    color: var(--main-color-700);
}

.tag-article {
    color: var(--main-color-900);
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0;
}

.article-content {
    width: 50%;
}
