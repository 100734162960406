.navbar-toggler {
    border: none !important;
    box-shadow: none !important;
}

.language-burger {
    display: flex;
    gap: 5px;
}

.language {
    color: #FFFFFF8C;
}

@media (min-width: 992px) {
    .language-burger {
        display: none !important;
    }

    .language-select {
        display: inline;
    }
}

@media (max-width: 991px) {
    .language-select {
        display: none;
    }
}
